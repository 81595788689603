
import { Link } from "gatsby"
import React, { useState, useCallback } from 'react';
import { Link as ExternalLink } from '../components/link';
import Layout from "../components/layout";
import HeroImage from '../images/hero-image.svg';
import { FullSacleTableModal } from '../components/full-scale-table';

const teamMembers = [
  {
    name: 'Hyung Park',
    title: 'Assistant Professor of Biostatistics',
  },
  {
    name: 'Thaddeus Tarpey',
    title: 'Professor of Biostatistics',
  },
  {
    name: 'Yi Li',
    title: 'Associate Research Scientist',
  },
  {
    name: 'Eva Petkova',
    title: 'Professor of Biostatistics',
  },
  {
    name: 'Elliott Antman',
    title: 'Professor of Cardiovascular Medicine',
  },
  {
    name: 'Andrea Troxel',
    title: 'Professor of Biostatistics',
    extraTitle:'Principal Investigator of COMPILE'
  },
]

export default function Home() {
  const [ openModal, setOpenModal ] = useState(false);
const handleTooltipClick = useCallback(() => setOpenModal(!openModal), [ openModal ]);
  return <Layout>
    
    <div className="home-hero-container">
    <FullSacleTableModal openModal={openModal} handleTooltipClick={handleTooltipClick} />

        <div className="container">
          <div className="home-hero">
            <div className="mb-4 mb-lg-0 home-hero__content">
              <h1 className="t-heading1 t-serif">Is Treatment With Convalescent Plasma Right for Your Patient</h1>
              <p className="my-4 t-body1">Your patient diagnosed with COVID-19 depends on you to prescribe the best treatment. One treatment option is transfusion of COVID-19 convalescent plasma (CCP). However, CCP is not uniformly efficacious for all patients.  The CCP treatment benefit index (TBI) uses each patient’s clinical characteristics to guide your treatment decisions, predicting the relative benefit from CCP treatment as compared to treatment without CCP transfusion.</p>
              <Link to="/calculator" className="btn primary home-hero__btn">Take me to the Benefit Calculator</Link>
            </div>
            <div className="home-hero__image">
              <img src={HeroImage}></img>
            </div>
          </div>
          <div className="home-sub-box my-5">
            <div className="home-sub-box__container">
              <h4 className="t-heading2 mb-3">How Was The Covid-19 Convalescent Plasma Treatment Benefit Index Developed?</h4>
              <p className="t-body1">The COVID-19 convalescent plasma (CCP) treatment benefit index (TBI)  was developed and tested using data from the Continuous Monitoring of Pooled International Trials of Convalescent Plasma (COMPILE) for COVID-19 Hospitalized Patients study. <ExternalLink link="https://med.nyu.edu/departments-institutes/population-health/divisions-sections-centers/biostatistics/research/continuous-monitoring-pooled-international-trials-convalescent-plasma-covid19-hospitalized-patients">COMPILE</ExternalLink> included 8 international randomized controlled trials (RCTs) and has individual data on a total of 2369  hospitalized adults (18 years old or older) with COVID-19, who may or may not need oxygen supplementation, but do not require mechanical ventilation.</p>
                <br /> <br />
              <h4 className="t-heading2 mb-3">What Does the CCP TBI Measure?</h4>
              <p className="t-body1">The TBI has been further validated in 2 external RCTs and two external observational  cohorts of patients treated with CCP. The treatment benefit is measured in terms of COVID-19 clinical status as assessed with the <button className="inline-btn" type="button" onClick={handleTooltipClick}>WHO 11-point Scale</button> . The TBI score indicates the relative benefit the patient is expected to have from treatment with CCP compared to a treatment without CCP.</p>
            </div>
          </div>
        </div>
    </div>
    <div className="home-our-team-container">

      <div className="our-team-section container">
        <div className="our-team-section__container">
          <h2 className="t-heading1 mb-3">Our Team</h2>
          <p className="t-body1">The CCP-TBI was developed by the COMPILE statistics team, members of the Division of Biostatistics at the Department of Population Health, New York University Grossman School of Medicine.</p>
        </div>
        <div className="our-team-cards mt-4">
          {teamMembers.map((member, idx) => (
            <div className="our-team-card" key={idx}>
              <label>{member.name}</label>
              <div className="our-team-card-title">{member.title}</div>
              {member.extraTitle && 
              <div className="our-team-card-title">{member.extraTitle}</div>
              }

            </div>
          ))}
        </div>
      </div>
    </div>

  </Layout>
}

